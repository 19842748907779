import React from "react";
import {
    Spin,
    Form,
    Card,
    Row,
    Col,
    Input,
    Select,
    Button,
    message,
    Popconfirm,
    DatePicker,
} from "antd";

import { moaApi } from "../../../../../api";
import {
    ACCOUNT_PLATFORM_TYPE
} from "../../../../../utils/constant";
import moment from "moment";
import UserSelector from "../../../../../components/user-selector";

const { Option } = Select;
const {
    reqAccountAdd,
    reqAccountDetail,
    reqAccountEdit,
    reqAccountDel,
    reqMobileList,
} = moaApi;

class PriceInput extends React.Component {
    handleNumberChange = e => {
        const number = parseInt(e.target.value || 0, 10);
        if (isNaN(number)) {
            return;
        }
        this.triggerChange(number);
    };

    triggerChange = changedValue => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(changedValue);
        }
    };

    render() {
        const { value, placeholder } = this.props;
        return (
            <span>
                <Input
                    type="text"
                    value={value}
                    onChange={this.handleNumberChange}
                    suffix="月"
                    placeholder={placeholder}
                />
            </span>
        );
    }
}

class AccountAdd extends React.Component {
    state = {
        loading: true,
        detail: {},
        mobile: [],

        authentication: false, //是否填写了认证信息
        email: false, //是否填写了绑定邮箱
    };
    id = this.props.match.params.id;

    handleChangeEmail = e => {
        if (e.target.value) {
            this.setState({
                email: true
            });
        } else {
            this.setState({
                email: false
            });
        }
    };
    handleChangeAuthentication = e => {
        if (e.target.value) {
            this.setState({
                authentication: true
            });
        } else {
            this.setState({
                authentication: false
            });
        }
    };

    getMobileList = async (params = {}) => {
        const response = await reqMobileList(params);
        const data = response.data;
        let newData = [];
        for (let i = 0; i < data.data.length; i++) {
            const item = data.data[i];
            const obj = {
                key: item._id,
                value: item._id,
                text: item.bound_mobile,
            };
            newData.push(obj);
        }
        this.setState({
            mobile: newData
        });
    };

    validate = async (values) => {
        values.register_time = new Date(values.register_time._d);
        values.final_certification_time = values.final_certification_time || '';
        values.certification_period = values.certification_period || '';
        let response;
        values.mobile_input_type = 1;
        if (!this.id) {
            for (let i in values) {
                if (!values[i]) {
                    delete values[i];
                }
            }
        }
        if (this.id) {
            values.id = this.id;
            response = await reqAccountEdit(values);
            if (response && response.data) {
                message.success('修改成功');
                this.props.history.goBack();
            }
        } else {
            response = await reqAccountAdd(values);
            if (response && response.data) {
                message.success('添加成功');
            }
        }
    };

    delAccount = async () => {
        const response = await reqAccountDel({
            id: this.id
        });
        if (response && response.data) {
            message.success('删除成功');
            this.props.history.replace('/moa/account/list');
        }
    };

    getAccountDetail = async (params = {}) => {
        const response = await reqAccountDetail(params);
        if (response && response.data) {
            this.setState({
                detail: response.data,
                loading: false
            });
        }
    };

    componentDidMount() {
        if (this.id) {
            // 获取详情
            this.getAccountDetail({
                id: this.id
            });
        }
        this.getMobileList({
            node: 999
        });
    }

    render() {
        const { detail } = this.state;

        return this.id && this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <Form onFinish={this.validate} layout="vertical">
                <Card
                    title={
                        this.id ? "修改账号信息" : "添加账号"
                    }
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={
                        this.id ? (
                            <Popconfirm
                                title="确定删除该账号信息吗?"
                                onConfirm={this.delAccount}
                                okText="确定"
                                cancelText="取消"
                            >
                                <Button>删除</Button>
                            </Popconfirm>
                        ) : null
                    }
                >
                    <Card
                        title="账号基本信息"
                        bordered={false}
                        type="inner"
                    >
                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="注册时间"
                                    name="register_time"
                                    initialValue={detail.register_time ? moment(detail.register_time) : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择注册时间',
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请选择注册时间" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="注册人"
                                    name="registrant"
                                    initialValue={detail.registrant && detail.registrant.id ? detail.registrant.id : []}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择注册人',
                                        },
                                    ]}
                                >
                                    <UserSelector
                                        placeholder="请选择注册人"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="平台"
                                    name="platform"
                                    initialValue={detail.platform || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入平台',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入平台" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="平台类别"
                                    name="platform_type"
                                    initialValue={detail.platform_type !== undefined ? detail.platform_type : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择平台类别',
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择平台类别">
                                        {ACCOUNT_PLATFORM_TYPE.map((item, index) => (
                                            <Option key={item.key} value={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="用途"
                                    name="use"
                                    initialValue={detail.use || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入用途',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入用途" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="用户名"
                                    name="user_name"
                                    initialValue={detail.user_name || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入用户名',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入用户名" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="账号"
                                    name="account"
                                    initialValue={detail.account || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入账号',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入账号" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="密码"
                                    name="password"
                                    initialValue={detail.password || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入密码',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入密码" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="维护人"
                                    name="maintenance_man"
                                    initialValue={detail.maintenance_man && detail.maintenance_man.id ? detail.maintenance_man.id : []}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择维护人',
                                        },
                                    ]}
                                >
                                    <UserSelector
                                        placeholder="请选择维护人"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="绑定手机"
                                    name="mobile"
                                    initialValue={detail.mobile ? detail.mobile.id : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择绑定手机',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="请选择绑定手机"
                                    >
                                        {
                                            this.state.mobile.map((item, index) => (
                                                <Option key={item.key} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="绑定邮箱"
                                    name="bound_email"
                                    initialValue={detail.bound_email || ''}
                                >
                                    <Input placeholder="请输入绑定邮箱" onChange={this.handleChangeEmail} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="绑定邮箱归属人"
                                    name="bound_email_owner"
                                    initialValue={detail.bound_email_owner && detail.bound_email_owner.id ? detail.bound_email_owner.id : undefined}
                                    rules={[
                                        {
                                            required: this.state.email,
                                            message: '请选择绑定邮箱归属人',
                                        },
                                    ]}
                                >
                                    <UserSelector
                                        placeholder="请选择绑定邮箱归属人"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="实名信息"
                                    name="real_name"
                                    initialValue={detail.real_name || ''}
                                >
                                    <Input placeholder="请输入实名信息" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="认证信息"
                                    name="certification_info"
                                    initialValue={detail.certification_info || ''}
                                >
                                    <Input placeholder="请输入认证信息" onChange={this.handleChangeAuthentication} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="最后认证完成时间"
                                    name="final_certification_time"
                                    initialValue={detail.final_certification_time ? moment(detail.final_certification_time) : undefined}
                                    rules={[
                                        {
                                            required: this.state.authentication,
                                            message: '请选择最后认证完成时间',
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请选择最后认证完成时间" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="认证有效期"
                                    name="certification_period"
                                    initialValue={detail.certification_period || ''}
                                    rules={[
                                        {
                                            required: this.state.authentication,
                                            message: '请输入认证有效期',
                                        },
                                    ]}
                                >
                                    <PriceInput placeholder="请输入认证有效期" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="主体"
                                    name="main_body"
                                    initialValue={detail.main_body || ''}
                                >
                                    <Input placeholder="请输入主体" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="备注"
                                    name="remark"
                                    initialValue={detail.remark || ''}
                                >
                                    <Input placeholder="请输入备注" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Card>

                <div className="submit-footer">
                    <Button type="primary" htmlType="submit">
                        {
                            this.id ? '保存' : '提交'
                        }
                    </Button>
                </div>
            </Form>
        );
    }
}

export default AccountAdd;